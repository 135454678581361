import React, { useEffect, useState } from "react";
import { Checkbox, Form, message, Steps, Row, Col, Spin} from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, SelectWithSearch, InputDate,InputText, UploadFile, SelectResourceList ,SelectMultiWithSearch} from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createDataReq, getEmployeeInfoByIdReq, getQuestionsReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList,createSelectEmploymentList } from '@comps/commonFunctions';
import { useSelector } from "react-redux";

const langKey = Const["EOEmployment"].lngKey
const formName = "createEndOfEmployment";

const CreateEndOfEmployment = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isNoticePeriod, setIsNoticePeriod] = useState(false);
  const [employeeInfo, setEmployeeInfo] = useState();
  const [isDailyWager, setIsDailyWager] = useState(false)
  const [hasReplacements, setHasReplacements] = useState(false);
  const [current, setCurrent] = useState(0);
  const [hasInterview,setHasInterview]=useState(false);
  const [stepOneData,setStepOneData]=useState({})
  const [questions,setQuestions]=useState([])
  const [interviewFormData,setInterviewFormData]=useState()
  const [replacement,setReplacement]=useState([]);
  const [finalAuthorities,setFinalAuthorities]=useState([]);
  const [linemanagers,setLinemanagers]=useState([]);
  const [hods,sethods]=useState([]);
  const [employments,setEmployments]=useState([]);

  useEffect(() => {
    makeRequestStateless(getQuestionsReq, null, onSuccessGetQuestions, null);
    // eslint-disable-next-line
  }, []);

  const onSuccessGetQuestions = (response) => {
let newList=[];
response.questions.forEach(each=>{
      if(each.type==="radio" || each.type==='checkbox'){
       let arr= each.data=each.data.split(',')
       let ops=[];
arr.forEach(ar=>{
  let obj={label:ar,value:ar}
  ops.push(obj)
})
each.data=ops;
      }
      if(each.is_active){
        newList.push(each);
      }
    })

    setQuestions(newList);
  }

  const onSubmit = (data) => {
    let endOfEmp={};
    let interview={}
    if(hasInterview && current!==1){
      setCurrent(current+1)
      setStepOneData(data);
      endOfEmp=data;
      return;
    }
   if(current===0){
    endOfEmp=data;
    setInterviewFormData(data)
   }
   if(current===1){
    interview=data
    endOfEmp=stepOneData;
   }
    // if (data.file_attachment !== undefined && data.file_attachment !== null && data.file_attachment.url === undefined) {
    //   data.file_attachment.file = data.file_attachment.fileList.length > 0 ? data.file_attachment.file : null
    // }

    // file_attachment: undefined
    // end_of_employment: { "employment_id": "ef65f25f-5a9f-4176-94b1-39d2c07fb8ef", "is_daily_wage": false, "reason_id": 5, "is_final_settlement": true, "resignation_date": "2023-12-08T00:00:00.000Z" }
    const payload = { end_of_employment: { ...endOfEmp, resignation_date: endOfEmp.resignation_date.format('YYYY-MM-DD'), is_daily_wage: isDailyWager }, interview:interview};
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {

    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCreated(data.end_of_employments.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  const onChangeIsGroup = (e) => {
    setIsNoticePeriod(e.target.checked);
  }

  const onChangeEmployment = (id) => {
    if (id) {
      setLoader(true)
      makeRequestStateless(getEmployeeInfoByIdReq, id, onSuccessEmploymentInfo, null);
    }
    else {
      setHasReplacements(false)
    }
  }

  const onSuccessEmploymentInfo = (response) => {
    setEmployeeInfo(response.emloyment)
    setIsDailyWager(response.is_daily_wager)
    const employment = response.employment;
    setReplacement([]);
    const tempReplacement=[];
    //show replacements
    if (employment.is_hod) {
      tempReplacement.push({'name':'as_hod',type:'hods',value:null});
      sethods(createSelectEmploymentList(response.hods))
    }
 
    if (employment.is_manager) {
      tempReplacement.push({'name':'as_manager',type:'linemanagers',value:null});
      setLinemanagers(createSelectEmploymentList(response.linemanagers))
    }
    if (employment.is_final_authority) {
      tempReplacement.push({'name':'as_final_authority',type:'finalAuthorities',value:null});
      setFinalAuthorities(createSelectEmploymentList(response.finalAuthorities))
    }
    if (employment.is_branch_manager) {
      tempReplacement.push({'name':'as_branch_manager',type:'employmentWithPagination',value:null});
    }
    if (employment.is_area_manager) {
      tempReplacement.push({'name':'as_area_manager',type:'employmentWithPagination',value:null});
    }
    if (employment.is_delegatee) {
      tempReplacement.push({'name':'as_delegatee',type:'employmentWithPagination',value:null});
    }

    if(response.employments){
      setEmployments(createSelectEmploymentList(response.employments))
    }


    if (employment.is_hod || employment.is_manager || employment.is_final_authority || employment.is_delegatee) {
      setHasReplacements(true);
      setReplacement(tempReplacement);
    }
    else {
      setHasReplacements(false);
    }
    setLoader(false)
  }
  //=========================================
  //============uploading file============
  // const [fileList, setFileList] = useState([]);
  // const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewImage, setPreviewImage] = useState('');
  // const [previewTitle, setPreviewTitle] = useState('');
  // const handleCancel = () => setPreviewOpen(false);
  // const getBase64 = (file) =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  // const onChange = ({ fileList: newFileList }) => {
  //   setFileList(newFileList);
  // };
  // const onPreview = async (file) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   setPreviewImage(file.url || file.preview);
  //   setPreviewOpen(true);
  //   //setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  // };
  //=========================================

  const getOptions=(each)=>{

    if (each.name==='as_hod') {
      return hods;
    }
 
    if (each.name==='as_manager') {
      return linemanagers;
    }
    if (each.name==='as_final_authority') {
      return finalAuthorities;
    }
    if (each.name==='as_branch_manager') {
      return employments;
    }
    if (each.name==='as_area_manager') {
      return employments;
    }
    if (each.name==='as_delegatee') {
      return employments;
    }
  }
  //steps


  const stepOne=()=>{
    return (
      <div className="m-5">
       <Form { ...formLayout } name={ formName } onFinish={ onSubmit } initialValues={stepOneData} scrollToFirstError>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
              initialValue={stepOneData.employment_id}
            >
              <SelectResourceList type='employment' disabled={ props.filters !== undefined } onChange={ onChangeEmployment } exclude_ids={ [authUser.employment_id] } activesOnly={true}/>
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="reason_id" rules={ rules.reason_id } label={ trans(`${langKey}.reason_id`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
              initialValue={stepOneData.reason_id}
            >
              <SelectWithSearch
                placeholder={ trans(`${langKey}.reason_id`) }
                options={ props.reasons.length !== 0 ? createSelectList(props.reasons) : '' }
              />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="resignation_date" rules={ rules.resignation_date } label={ trans(`${langKey}.resignation_date`) }
              className="da-mb-16"
              initialValue={stepOneData.resignation_date}
            >
              <InputDate />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="notice_period" rules={ rules.notice_period } label={ trans(`${langKey}.notice_period`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
              valuePropName="checked"
              initialValue={stepOneData.notice_period}
            >
              <Checkbox onChange={ onChangeIsGroup } />
            </Form.Item>
          </Col>

          {
            isNoticePeriod ?
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="last_working_date" rules={ rules.last_working_date } label={ trans(`${langKey}.last_working_date`) }
                  className="da-mb-16"
                  initialValue={stepOneData.last_working_date}
                >
                  <InputDate />
                </Form.Item> </Col> :
              null

          }

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="is_final_settlement" rules={ rules.is_final_settlement } label={ trans(`${langKey}.is_final_settlement`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
              valuePropName="checked"
              initialValue={stepOneData.is_final_settlement}
            >
              <Checkbox />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="has_interview" rules={ rules.has_interview } label={ trans(`${langKey}.has_interview`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
              valuePropName="checked"
             initialValue={ hasInterview}
            >
              <Checkbox checked={ hasInterview ?true:false} onChange={(e)=>setHasInterview(e.target.checked)}/>
            </Form.Item>
          </Col>
          {/* <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item label={ trans(`${langKey}.attachment`) } rules={ rules.attachment } name="file_attachment" >
              <UploadFile
                accept=".jpg,.png,.jpeg" listType="picture-card"
                fileList={ fileList }
                onChange={ onChange }
                onPreview={ onPreview }
                multiple={ false }
                beforeUpload={ (file) => {
                  return false;
                } }
              >
                { fileList.length === 1 ? null : <UploadButton /> }
              </UploadFile>
            </Form.Item>
          </Col> */}
          { hasReplacements &&
          
          <>
         
          <div className="formlist-container w-100">
          <h5 className="formlist-title">Replacements</h5>
          <Form.List name="replacement" >
      { (fields, { add, remove }) => (
        <>
          
          <div className="table-container">
            <table className="table w-100">
              
                 <thead>
                  <tr>

                    {
                      replacement.map((config,key) => (
                        <th  key={key} style={ { paddingLeft: 20 } }>{ `${config.name.replace('_',' ')}` }</th>
                      ))
                    }
                   

                  </tr>
                </thead>
              
              <tbody>
                
                  <>
                    <tr>
                      {
                        replacement.map(config => (
                          <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                            <Form.Item
                             
                              name={ config.name }
                            rules={ rules.required }
                            >
                             <SelectWithSearch options={getOptions(config)} placeholder={config.name.replace("_",' ')}/>
                            </Form.Item>
                          </td>
                        ))
                      }

                     
                    </tr>
                  </>
                
              </tbody>
            </table>
          </div>
        </>
      ) }
    </Form.List>
</div>
          </>
  }
        </Row>
      </Form>
      </div>
    )
  }

  const stepTwo=()=>{
    return (
      <div className="m-5">

        {questions.length<1 && <div className="h6 text-center text-danger">Create question answers first from settings of end of employment!</div>}
<Form { ...formLayout } name={formName} initialValues={interviewFormData} onFinish={onSubmit}>


     {
      questions.map((question,key)=>(
        <Col>
            <Form.Item name={question.id} label={ question.question_no+'. '+question.name }
              className="da-mb-16"
              rules={ question.required?rules.required:'' }
            >
              {question.type==='text'?<InputText placeholder={question.name}/>:question.type==='date'?<InputDate/>:question.type==='radio'?<SelectWithSearch placeholder={question.name}
              options={
                question.data
              }/>:
              <SelectMultiWithSearch placeholder={question.name}
              options={question.data}/>}
            </Form.Item>
          </Col>
      ))
     }

</Form>
      </div>
    )
  }

  const steps = [
    {
      title: 'End of Employment',
      content: stepOne(),
    },
    {
      title: 'Interview Form',
      content: stepTwo(),
    },
  ]

  const next = () => {

    setCurrent(current + 1);

  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  //end steps
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    current > 0 && (
      <SaveButton
      text="Previous"
        style={ {
          margin: '0 8px',
        } }
        onClick={ () => prev() }
      >
        Previous
      </SaveButton>
    ),
    // current < steps.length - 1 && hasInterview ? (
    //   <SaveButton text="Next" type="primary" onClick={ () => next() }>
    //     Next
    //   </SaveButton>
    // ):'',
    
    <SaveButton text={current < steps.length - 1 && hasInterview ? 'Next':'Save'} disabled={ saveLoader } form={ formName  } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1300 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCreated(false) }>

   <Spin spinning={loader} tip="Loading Related Data">
   <Steps current={ current } items={ items } />
          <div >{ steps[current].content }</div>
      {/* <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
        <img
          alt="example"
          style={ {
            width: '100%',
          } }
          src={ previewImage }
        />
      </Modal> */}
   </Spin>
     
    </ModalComponent>


  )
}

export default CreateEndOfEmployment

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id.req`) },
  ],
  reason_id: [
    { required: true, message: trans(`${langKey}.reason_id`) },
  ],
  resignation_date: [
    { required: true, message: trans(`${langKey}.resignation_date`) },
  ],
  last_working_date: [
    { required: true, message: trans(`${langKey}.last_working_date`) },
  ],
  required: [
    { required: true, message: "This Field is Required!" },
  ],



};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}